<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <b-card :title="`Manage ${incubator.title}`">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search"
                  type="text"
                />
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.addMemberModal
                  class="d-inline-flex mr-1"
                  variant="outline-success"
                >
                  <feather-icon
                    class="mr-50"
                    icon="PlusIcon"
                  />
                  <span class="align-middle text-nowrap">Add Member</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.manageIncubator
                  class="d-inline-flex mr-1"
                  variant="outline-primary"
                >
                  <feather-icon
                    class="mr-50"
                    icon="SettingsIcon"
                  />
                  <span class="align-middle text-nowrap">Update Incubator Details</span>
                </b-button>
              </div>
            </b-form-group>
          </div>
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="incubator.members"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span
                v-if="props.column.field === 'designation'"
              >

                <span v-if="props.row.editable">
                  <b-input-group>
                    <b-form-input
                      id="updateDesignation"
                      v-model="designation"
                      placeholder="Designation"
                    />
                    <template #append>
                      <b-button
                        v-b-tooltip="'Update'"
                        size="sm"
                        variant="outline-primary"
                        @click="props.row.editable = !props.row.editable; editDesignation(props.row.assoc_id)"
                      >
                        <feather-icon
                          icon="CheckIcon"
                        />
                      </b-button>
                    </template>
                  </b-input-group>
                  <small v-if="props.row.editable && props.row.designation !== designation">Previous: {{
                    props.row.designation
                  }}</small>
                </span>
                <span
                  v-else
                  class="d-flex justify-content-between"
                > {{ props.row.designation }} <b-button
                  v-b-tooltip="'Edit'"
                  size="sm"
                  variant="flat-secondary"
                  @click="designation = props.row.designation; props.row.editable = !props.row.editable"
                >
                  <feather-icon
                    icon="Edit2Icon"
                  />

                </b-button></span>

              </span>

              <span v-else-if="props.column.field === 'action'">
                <b-button
                  size="sm"
                  variant="flat-danger"
                  :disabled="orgAssocId === props.row.assoc_id"
                  @click="orgAssocId !== props.row.assoc_id ? deleteMember(props.row.assoc_id) : null"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="18"
                  />
                </b-button>
              </span>
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
        <b-modal
          id="manageIncubator"
          ok-title="Update"
          size="lg"
          title="Incubator Details"
          no-close-on-backdrop
          @ok="formSubmitted"
        >
          <validation-observer
            ref="incubatorBasicInfoRules"
            tag="form"
          >
            <b-row>

              <!-- <b-col md="4">
                      <b-form-group
                        label="Upload Logo *"
                        label-for="designation"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Designation"
                          rules="required"
                        >
                          <b-form-input
                            id="designation"
                            v-model="incubator.designation"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Your Designation"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col> -->
              <b-col
                cols="6"
              ><span class="d-block">Current Logo </span> <Promised
                v-if="incubator.logo"
                :promise="getLinkFromS3(incubator.logo)"
              >
                <template v-slot:pending>
                  <b-spinner />
                </template>
                <template v-slot:default="data">
                  <b-img
                    :src="data"
                    fluid
                    class="border img-fluid mb-1 p-50 rounded w-75"
                  />
                </template>
                <template v-slot:rejected>
                  <span>
                    No image uploaded.
                  </span>
                </template>
              </Promised></b-col>
              <b-col
                cols="6"
                class="align-content-center"
              >
                <!-- Styled -->

                <b-form-group
                  label="Upload New Logo"
                  label-for="programPoster"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="File"
                    rules="size:10000"
                  >
                    <b-form-file
                      v-model="incubator.file"
                      :state="errors.length > 0 ? false:null"
                      accept="image/jpeg, image/png, image/gif"
                      drop-placeholder="Drop file here..."
                      placeholder="Choose a file or drop it here..."
                      style="margin-bottom: 0.5rem;"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Incubator Name *"
                  label-for="incubator"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Incubator Name"
                    rules="required"
                  >
                    <b-form-input
                      id="incubator"
                      v-model="incubator.title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Incubator Name"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="incubator.description"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Incubator Description"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Official Email *"
                  label-for="official_email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Official Email"
                    rules="required"
                  >
                    <b-form-input
                      id="official_email"
                      v-model="incubator.official_email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="hello@yourbusiness.com"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Phone Number *"
                  label-for="url"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Phone Number"
                    rules="required"
                  >
                    <b-form-input
                      id="official_phone"
                      v-model="incubator.official_phone"
                      :state="errors.length > 0 ? false:null"
                      placeholder="+91-1234567890"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Website"
                  label-for="url"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Website"
                  >
                    <b-form-input
                      id="url"
                      v-model="incubator.url"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Incubator Website URL"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Industry"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                    label="Industry *"
                    label-for="industry"
                  >
                    <v-select
                      id="industry"
                      v-model="industryArray"
                      :options="industries"
                      placeholder="Select Industry"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Lifecycle Stage"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                    label="Lifecycle Stage *"
                    label-for="startup_stage"
                  >
                    <v-select
                      id="startup_stage"
                      v-model="startup_stageArray"
                      :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                      placeholder="Select Lifecycle Stage"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="City *"
                  label-for="city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <b-form-input
                      id="city"
                      v-model="incubator.city"
                      :state="errors.length > 0 ? false:null"
                      placeholder="City"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="State"
                  label-for="state"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="State"
                  >
                    <b-form-input
                      id="state"
                      v-model="incubator.state"
                      :state="errors.length > 0 ? false:null"
                      placeholder="State"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <validation-provider
                    name="Country"
                  >
                    <b-form-input
                      id="country"
                      v-model="incubator.country"
                      placeholder="Country"
                      type="text"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-modal>

        <b-modal
          id="addMemberModal"
          size="lg"
          title="Add Member"
          hide-footer
          no-close-on-backdrop
        >
          <b-row>
            <b-col>
              <b-form-group
                label="Email"
                label-for="member"
              >
                <b-overlay
                  :show="mutationLoading"
                >
                  <div class="bg-light rounded-sm">
                    <b-form-input
                      id="member"
                      v-model="member.email"
                      lazy
                      placeholder="Enter Email"
                      type="email"
                      @blur="fetchUser()"
                    />
                    <p
                      v-show="member.email && !mutationLoading"
                      class="px-1 py-50"
                    >Result:
                      <b-badge
                        v-if="searchData === false"
                        pill
                        variant="danger"
                      >No User Found!
                      </b-badge>
                      <b-badge
                        v-else-if="member.id && existingUser"
                        class="ml-25"
                        pill
                        variant="danger"
                      >
                        User already exists!
                      </b-badge>
                      <b-badge
                        v-else
                        class="text-capitalize"
                        pill
                        variant="primary"
                      >{{ searchData }}
                      </b-badge>

                    </p>
                  </div>
                </b-overlay>

              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Designation"
                label-for="memberDesignation"
              >
                <b-form-input
                  id="memberDesignation"
                  v-model="member.designation"
                  placeholder="Designation of member"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col cols="auto">
              <b-button
                :disabled="!member.id || existingUser"
                class="mt-2"
                variant="outline-primary"
                @click="$bvModal.hide('addMemberModal'); addMember()"
              >
                <feather-icon
                  class="mr-25"
                  icon="PlusIcon"
                />
                Add
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormFile,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BModal,
  BRow,
  BImg,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import { Promised } from 'vue-promised'
import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BSpinner,
    BBadge,
    BModal,
    BImg,
    BCard,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BFormFile,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    vSelect,
    Promised,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueGoodTable,
  },
  data() {
    return {
      mutationLoading: false,
      columns: [
        {
          label: 'Name',
          field: 'full_name',
        },
        {
          label: 'Designation',
          field: 'designation',
        },
        {
          label: 'Email',
          field: 'email',
        },

        // {
        //   label: 'Status',
        //   field: 'status',
        // },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      searchData: '',
      member: {
        id: null,
        email: null,
        designation: null,
      },
      searchTerm: '',
      designation: '',
      pageLength: 5,
      incubator: {
        title: null,
        designation: null,
        description: null,
        official_email: null,
        official_phone: null,
        url: null,
        logo: null,
        industry: '',
        startup_stage: '',
        city: null,
        state: null,
        country: null,
        file: null,
        members: [],
      },
      industries: ['Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
      required,
      email,
    }
  },
  computed: {
    orgAssocId() {
      return getUserData().associatedOrgDetails.filter(org => org.role === 'superadmin')[0].id
    },
    existingUser() {
      return this.incubator.members.findIndex(e => e.id === this.member.id) !== -1
    },
    industryArray: {
      get() {
        // Convert the comma-separated string to an array
        return this.incubator.industry ? this.incubator.industry.split(',') : []
      },
      set(value) {
        this.incubator.industry = value.join(',')
      },
    },
    startup_stageArray: {
      get() {
        return this.incubator.startup_stage ? this.incubator.startup_stage.split(',') : []
      },
      set(value) {
        this.incubator.startup_stage = value.join(',')
      },
    },
  },
  methods: {

    formSubmitted() {
      this.addIncubator()
      this.mutationLoading = true
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.incubatorBasicInfoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    mutationObject() {
      const incubatorDetails = { ...this.incubator }
      delete incubatorDetails.assoc_id
      delete incubatorDetails.designation
      delete incubatorDetails.members
      delete incubatorDetails.users_associationtables
      return incubatorDetails
    },
    async uploadFile() {
      let fileLink = ''
      const formData = new FormData()
      formData.append('file', this.incubator.file)
      await fetch('https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/upload/', {
        method: 'POST',
        body: formData,
      }).then(response => response.json())
        .then(data => {
          fileLink = data.file_key
          return data
        })
      return fileLink
    },
    addIncubator() {
      if (this.incubator.file) {
        this.uploadFile().then(data => {
          this.incubator.logo = data
          delete this.incubator.file
          this.addIncubator()
        })
        return false
      }
      // Update incubator details (users_organizationtable)
      this.$apollo.mutate({
        mutation: gql`
          mutation($object: users_organizationtable_insert_input!) {
            insert_users_organizationtable_one(object: $object, on_conflict: {constraint: users_organizationtable_pkey, update_columns: [title, description, official_email,logo, official_phone, url, industry, startup_stage, city, state, country]} ) {
              id
            }
          }`,
        variables: { object: this.mutationObject() },
        update: () => {
          this.$apollo.queries.incubator.refetch()
          this.mutationLoading = false
          this.$bvModal.hide('manageIncubator')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        },
      })
      return true
    },
    fetchUser() {
      if (!this.member.email) return false
      this.mutationLoading = true
      this.$apollo.query({
        query: gql`query options($q: String!){
              users_customuser(where: {email: {_ilike: $q}}) {
                      full_name
                      email
                      id
                    }
              }`,
        variables: { q: this.member.email },
      })
        .then(({ data }) => {
          this.mutationLoading = false
          this.searchData = !data.users_customuser[0] ? false : data.users_customuser[0].full_name
          this.member.id = data.users_customuser[0]?.id || null
        })
      return true
    },
    addMember() {
      this.$apollo.mutate({
        mutation: gql`
          mutation {
            insert_users_associationtable_one(object: {organization_id: ${this.incubator.id}, designation: "${this.member.designation}", role: "superadmin", status: "Invited", user_id: ${this.member.id}}) {
              id
            }
          }`,
        update: () => {
          this.member.id = null
          this.member.designation = null
          this.$apollo.queries.incubator.refetch()
        },
      })
    },
    editDesignation(assocId) {
      if (!this.designation) return this.showAlert('Designation cannot be empty', 'danger')
      const oldDesgination = this.incubator.members.find(e => e.assoc_id === assocId).designation
      if (oldDesgination === this.designation) return false
      this.$apollo.mutate({
        mutation: gql`
          mutation {
            update_users_associationtable_by_pk(pk_columns: {id: ${assocId}}, _set: {designation: "${this.designation}"}) {
              id
            }
          }`,
        // Show a toast if id is present in return and refetch
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          if (update_users_associationtable_by_pk.id) this.showAlert('Designation updated successfully', 'success')
          else this.showAlert('Failed to update designation', 'danger')
          this.$apollo.queries.incubator.refetch()
        },

      })
      return true
    },
    // Delete Member (set is_deleted to true)
    deleteMember(id) {
      // Confirm Delete (use this.$bvModal.msgBoxOk)
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this member?', {
        title: 'Confirm Delete',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.$apollo.mutate({
              mutation: gql`
          mutation {
            update_users_associationtable_by_pk(pk_columns: {id: ${id}}, _set: {is_deleted: true}) {
              id
            }
          }`,
              update: () => {
                this.$apollo.queries.incubator.refetch()
              },
            })
          }
        })
    },
  },
  apollo: {
    incubator: {
      query() {
        return gql`{
          users_associationtable_by_pk(id: ${this.orgAssocId}) {
            id
            designation
            users_organizationtable {
              id
              title
              description
              official_email
              official_phone
              url
              industry
              startup_stage
              city
              state
              country
              logo
              users_associationtables(where: {role: {_eq: "superadmin"}}) {
                id
                is_deleted
                designation
                users_customuser {
                  id
                  full_name
                  email
                }
              }
            }
          }
        }`
      },
      update(data) {
        return ({
          assoc_id: data.users_associationtable_by_pk.id,
          designation: data.users_associationtable_by_pk.designation,
          ...data.users_associationtable_by_pk.users_organizationtable,
          members: data.users_associationtable_by_pk.users_organizationtable.users_associationtables.map(e => ({
            ...e.users_customuser,
            designation: e.designation,
            assoc_id: e.id,
            editable: false,
          })),
        })
      },
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
